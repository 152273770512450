'use strict';

const { alertMessage } = require('../common/common');
const base = require('../product/base');
const common = require("../common/common");
const { SELECTORS, CLASSES, EVENTS } = require('./variables');

class Cart {
    constructor(element) {
        this.element = element;
        this.minicart = $(SELECTORS.minicart);
        this.minicartQty = $(SELECTORS.minicartQty);
        this.minicartLink = $(SELECTORS.minicartLink);
        this.editProductModal = $(SELECTORS.editProductModal);
        this.initEvents();
    }

    initEvents() {
        $('body').off('click', SELECTORS.removeProduct).on('click', SELECTORS.removeProduct, (e) => {
            e.preventDefault();
            const $this = $(e.currentTarget);
            const url = $this.data('action');
            const productID = $this.data('pid');
            const uuid = $this.data('uuid');
    
            this.removeProduct(productID, uuid, url, $this);
        });

        this.element.on('click', SELECTORS.bonusProductButton, () => {
            $.spinner().start();
            $(this).addClass(CLASSES.launchedModal);
            $.ajax({
                url: $(this).data('url'),
                method: 'GET',
                dataType: 'json',
                success: (data) => {
                    base.methods.editBonusProducts(data);
                    $.spinner().stop();
                },
                error: () => {
                    $.spinner().stop();
                }
            });
        });

        $('body').on('click', SELECTORS.edit, (evt) => {
            evt.preventDefault();
    
            const editProductUrl = $(evt.currentTarget).attr('href');
            this.fillModalElement(editProductUrl);
        });

        $('body').on(EVENTS.updateAddToCart, (e, response) => {
            const dialog = $(response.$productContainer).closest('.quick-view-dialog');
            $(SELECTORS.updateCartProductGlobal, dialog).attr(
                'disabled',
                !$(SELECTORS.globalAvailability, dialog).data('ready-to-order')
                || !$(SELECTORS.globalAvailability, dialog).data('available')
            );
            if ($(SELECTORS.globalAvailability, dialog).length < 1) {
                $(SELECTORS.updateCartProductGlobal, dialog).attr(
                    'disabled', !response.product.readyToOrder
                );
                $(SELECTORS.updateCartProductGlobal).attr('data-pid', response.product.id);
            }
        });
    
        $('body').on(EVENTS.updateAvailability, (e, response) => {
            // bundle individual products
            $(SELECTORS.productAvailability, response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
    
            const dialog = $(response.$productContainer).closest(SELECTORS.quickViewDialog);
    
            if ($(SELECTORS.productAvailability, dialog).length) {
                const allAvailable = $(SELECTORS.productAvailability, dialog).toArray().every(function (item) { return $(item).data('available'); });
                const allReady = $(SELECTORS.productAvailability, dialog).toArray().every(function (item) { return $(item).data('ready-to-order'); });
                $(SELECTORS.globalAvailability, dialog).data('ready-to-order', allReady).data('available', allAvailable);
                $(SELECTORS.availabilityMsg, dialog).empty().html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                $(SELECTORS.globalAvailability, dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
                if ($(SELECTORS.globalAvailability, dialog).length < 1) {
                    if ($(SELECTORS.updateCartProductGlobal).length == 1) {
                        $(SELECTORS.updateCartProductGlobal).attr('disabled', response.product.readyToOrder);
                        $(SELECTORS.updateCartProductGlobal).attr('data-pid', response.product.id);
                    }
                }
            }
        });
    
        $('body').on(EVENTS.afterAttributeSelect, (e, response) => {
            if ($('.modal.show .product-quickview .bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
            }
        });

        $('body').on('input', SELECTORS.changeQty, (evt) => {
            const $this = $(evt.currentTarget);
            const selectedQuantity = $this.val();
            $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
        });
    
        $('body').on('change', SELECTORS.optionsSelect, (evt) => {
            const $this = $(evt.currentTarget);
            const selectedOptionValueId = $this.children('option:selected').data('value-id');
            $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
        });

        $('body').on('quantiy:negative', (e, data) => {
            let $target = $(data.target);
            let productID = $target .data('pid');
            let uuid = $target.data('uuid');
            let removeAction = $target.data('remove-action');

            this.removeProduct(productID, uuid, removeAction);
        });

        $('body').on('input', SELECTORS.quantity, (e) => {
            let $this = $(e.currentTarget);
            let preSelectQty = $this.data('pre-select-qty');
            let quantity = $this.val();
            let productID = $this.data('pid');
            let url = $this.data('action');
            let uuid = $this.data('uuid');
            let urlParams = {
                pid: productID,
                quantity: quantity,
                uuid: uuid
            };
            url = this.appendToUrl(url, urlParams);
            $('body').trigger(EVENTS.cartBeforeUpdate);
            $.spinner().start();
    
            $.ajax({
                url: url,
                type: 'get',
                context: this,
                dataType: 'json',
                success: (data) => {
                    $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    this.updateCartTotals(data);
                    this.updateApproachingDiscounts(data.approachingDiscounts);
                    this.updateAvailability(data, uuid);
                    this.validateBasket(data);
                    $this.data('pre-select-qty', quantity);
    
                    $('body').trigger(EVENTS.cartUpdate, data);
    
                    if ($this.parents('.product-info').hasClass('bonus-product-line-item') && $(SELECTORS.element).length) {
                        location.reload();
                        return;
                    }

                    $.spinner().stop();
                },
                error: (err) => {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
                        $this.val(parseInt(preSelectQty, 10));
                        $.spinner().stop();
                    }
                }
            });
        });
    
        $('body').on('click', SELECTORS.updateCartProductGlobal, (e) => {
            e.preventDefault();
            const $this = $(e.currentTarget);
            const updateProductUrl = $this.closest('.cart-and-ipay').find('.update-cart-url').val();
            const selectedQuantity = $this.closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
            const selectedOptionValueId = $this.closest('.detail-panel').find('.options-select.selected').data('value-id') ? 
                $this.closest('.detail-panel').find('.options-select.selected').data('value-id')  :  
                $this.closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
            const uuid = $this.closest('.cart-and-ipay').find('.update-cart-url').data('uuid');
    
            var form = {
                uuid: uuid,
                pid: base.getPidValue($this),
                quantity: selectedQuantity,
                selectedOptionValueId: selectedOptionValueId
            };
    
            $('body').trigger(EVENTS.cartBeforeUpdate);

            $.spinner().start();
    
            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: (data) => {
                        this.editProductModal.modal('hide');
                        $(SELECTORS.couponsAndPromo).empty().append(data.cartModel.totals.discountsHtml);
                        this.updateCartTotals(data.cartModel);
                        this.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        this.updateAvailability(data.cartModel, uuid);
                        this.updateProductDetails(data, uuid);
    
                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }
                        this.validateBasket(data.cartModel);
                        $('body').trigger('cart:update', data);
                        $('body').trigger(EVENTS.afterEditItem, uuid);
                        $.spinner().stop();
                    },
                    error: (err) => {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
                        }
                        $.spinner().stop();
                    }
                });
            }
        });

        $('body').on('click', SELECTORS.proSubscription, (e) => {
            $.spinner().start();
            const url = $(e.currentTarget).attr('data-action');
            $.ajax({
                url: url,
                method: 'POST',
                data: '',
                success: () => {
                    window.location.reload();
                },
                error: (err) => {
                    console.error(err);
                    $.spinner().stop();
                }
            });
        });

        $('body').off('click', SELECTORS.resendEmail).on('click', SELECTORS.resendEmail, (e) => {
            $.spinner().start();
            const url = $(e.currentTarget).attr('data-action');
            $.ajax({
                url: url,
                method: 'GET',
                data: '',
                success: (data) => {
                    if (data.success) {
                        alertMessage(data.message, 'cc-alert--success');
                    } else {
                        alertMessage(data.errorMsg, 'cc-alert--danger');
                    }
                    $.spinner().stop();
                },
                error: (err) => {
                    console.error(err);
                    if (err.errorMsg) {
                        alertMessage(err.errorMsg, 'cc-alert--danger');
                    }
                    $.spinner().stop();
                }
            });
        });

        $(SELECTORS.promoForm).on('submit', (e) => {
            e.preventDefault();
            this.couponFormHandler();
        });

        $('body').on('click', SELECTORS.proCouponBtn, (e) => {
            e.preventDefault();
            const $couponField = $('#couponCode');
            const couponCode = $(e.currentTarget).data('code');

            if ($(e.currentTarget).hasClass('cc-active')) {
                $(e.currentTarget).removeClass('cc-active remove-coupon');
                return;
            }

            if ($couponField.val() && $couponField.val().length > 0) {
                $(SELECTORS.proCouponModal).modal('show');
                $(SELECTORS.proCouponConfirm).data('code', couponCode);
                return;
            }

            $couponField.val(couponCode);
            this.couponFormHandler();
        });

        $('body').on('click', SELECTORS.proCouponConfirm, (e) => {
            e.preventDefault();
            const couponCode = $(e.currentTarget).data('code');
            const $couponField = $('#couponCode');
            $couponField.val(couponCode);
            this.couponFormHandler();
        });

        $('body').on('click', SELECTORS.moveToWishlist, (e) => {
            e.preventDefault();
            const $this = $(e.currentTarget);
            const url = $this.attr('href');
            const pid = $this.data('pid');
            let optionId = $this.closest('.product-info').find('.line-item-option').data('option-id');
            let optionVal = $this.closest('.product-info').find('.line-item-option').data('option-selected-value');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }
    
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: (data) => {
                    $.spinner().stop();
                    alertMessage(data.msg, 'cc-alert--success');
                    const $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
                    let actionUrl = $targetElement.data('action');
                    const productID = $targetElement.data('pid');
                    const uuid = $targetElement.data('uuid');
                    this.removeProduct(productID, uuid, actionUrl);
                },
                error: (err) => {
                    $.spinner().stop();
                    alertMessage(err.msg, 'cc-alert--danger');
                }
            });
        });

        $('body').on('click', SELECTORS.removeCoupon, (e) => {
            e.preventDefault();
            const $this = $(e.currentTarget);
            let url = $this.data('action');
            const couponCode = $this.data('code');
            const uuid = $this.data('uuid');
            const urlParams = {
                code: couponCode,
                uuid: uuid
            };
    
            url = this.appendToUrl(url, urlParams);
    
            $('body > .modal-backdrop').remove();
    
            $.spinner().start();
            $('body').trigger('promotion:beforeUpdate');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: (data) => {
                    $('.coupon-uuid-' + uuid).remove();
                    $(SELECTORS.promoForm).removeClass(CLASSES.couponApplied);
                    $(SELECTORS.couponCodeInput).val('');
                    $(SELECTORS.proCouponBtn).removeClass('cc-active remove-coupon');
                    this.updateCartTotals(data);
                    this.updateApproachingDiscounts(data.approachingDiscounts);
                    this.validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                },
                error: (err) => {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
                        $.spinner().stop();
                    }
                }
            });
        });

        $('body').on(EVENTS.afterAddToCart, (e, response) => {
            if ($('.cart-page.js-cart').length > 0) {
                window.location.reload();
            }
        });

        if ($(SELECTORS.cartFinalSubtotal).length > 0 && $(SELECTORS.cartFixedButton).length > 0) {
            common.getWindowSizes();
            const stickySectionHeight = $(SELECTORS.cartFixedButton).outerHeight();

            $(window).on('scroll', () => {
                if (!window.isDesktop) {
                    let subtotalTop = $(SELECTORS.cartFinalSubtotal).offset().top;
                    let scrollTop = $(window).scrollTop();
                    let windowHeight = $(window).innerHeight();

                    if ((scrollTop + windowHeight - stickySectionHeight + 17) >= (subtotalTop)) {
                        $(SELECTORS.cartFixedButton).addClass(CLASSES.hide);
                    } else {
                        $(SELECTORS.cartFixedButton).removeClass(CLASSES.hide);
                    }
                }
            });
        }
    }

    /**
     * Handles the submission of the coupon form
     * @returns {void}
     */
    couponFormHandler() {
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $(SELECTORS.promoForm);
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        const formattedVal = $form.find('.coupon-code-field').val().replace(/\s/g, '');
        $form.find('.coupon-code-field').val(formattedVal);
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: (data) => {
                if (data.error) {
                    $(SELECTORS.promoForm).removeClass(CLASSES.couponApplied);
                    $(SELECTORS.proCouponBtn).removeClass('cc-active remove-coupon');
                    $(SELECTORS.couponCodeInput).val('');
                    if (data.basket) {
                        this.updateCartTotals(data.basket);
                        this.updateApproachingDiscounts(data.basket.approachingDiscounts);
                        this.validateBasket(data.basket);
                    }
                    $('body').trigger('promotion:error', data);
                    $.spinner().stop();
                    alertMessage(data.errorMessage, 'cc-alert--danger');
                } else {
                    const discounts = data.totals.discounts;
                    let couponCode = '';

                    this.updateCartTotals(data);
                    this.updateApproachingDiscounts(data.approachingDiscounts);
                    this.validateBasket(data);
                    $('body').trigger('promotion:success', data);

                    if (discounts && discounts.length > 0) {
                        discounts.forEach((element) => {
                            if (element.type === 'coupon') {
                                $(SELECTORS.removeCoupon).data('code', element.couponCode);
                                $(SELECTORS.removeCoupon).data('uuid', element.UUID);
                                couponCode = element.couponCode;
                            }
                        });
                    }

                    if (couponCode) {
                        $(SELECTORS.promoForm).addClass(CLASSES.couponApplied);
                        $(SELECTORS.proCouponBtn).removeClass('cc-active remove-coupon');
                        $(SELECTORS.proCouponBtn + '[data-code="' + couponCode + '"]').addClass('cc-active remove-coupon');
                    } else {
                        const $couponField = $('#couponCode');
                        $couponField.val('');
                    }

                    $.spinner().stop();
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                $(SELECTORS.couponCodeInput).val('');
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                    $.spinner().stop();
                } else {
                    alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
                    $.spinner().stop();
                }
            }
        });
    }

    /**
     * Removes a product from the cart.
     *
     * @param {string} productID - The ID of the product to be removed.
     * @param {string} uuid - The UUID of the product to be removed.
     * @param {string} url - The URL to send the request to.
     * @returns {void}
     */
    removeProduct(productID, uuid, url, target = null) {
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = this.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger(EVENTS.cartBeforeUpdate);

        const $self = this;

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: (data) => {
                if (data.basket.items.length === 0) {
                    if ($(SELECTORS.page).data('action') === 'Cart-Show') {
                        window.location.reload();
                        return;
                    }
                    this.element.addClass(CLASSES.emptyCart);
                    this.minicart.addClass(CLASSES.emptyCart);
                    this.minicartQty.empty().append(data.basket.numItems);
                    this.minicartQty.css('display', () => data.basket.numItems === 0 ? 'none' : 'inline-flex');
                    this.minicartLink.attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $(SELECTORS.bonusProduct).remove();
                    }
                    $(SELECTORS.couponsAndPromo).empty().append(data.basket.totals.discountsHtml);
                    this.updateCartTotals(data.basket);
                    this.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger(EVENTS.setShippingMethodSelection, data.basket);
                    this.validateBasket(data.basket);
                }

                if ($(SELECTORS.page).data('action') !== 'Cart-Show') {
                    $('body').trigger(EVENTS.minicartRefresh);
                } else {
                    if (target && target.hasClass(CLASSES.removeSub)) {
                        window.location.reload();
                        return;
                    }
                }

                $('body').trigger(EVENTS.cartUpdate, data);

                // GA4 EVENT
                $('.cart-item-actions .button-text').trigger('product:delete', { pid: urlParams.pid, action: 'deleteProduct' });

                // LOYALTY APP CART EVENT
                if($('body > .js-loyaltyApp-dataset').data('loyaltyapp')) {
                    var mobileCart = require('../mobileapp/mobile-cart');
                    mobileCart.sendEvent();
                }

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
                    $.spinner().stop();
                }
            }
        });
    }

    /**
     * Replaces the content in the modal window for product variation to be edited.
     * @param {string} editProductUrl - url to be used to retrieve a new product model
     */
    fillModalElement(editProductUrl) {
        $.ajax({
            url: editProductUrl,
            method: 'GET',
            dataType: 'json',
            success: (data) => {
                const parsedHtml = this.parseHtml(data.renderedTemplate);
                this.editProductModal.find('.js-modal-body').empty().html(parsedHtml.body);
                const targetModal = $(SELECTORS.edit).data('target');
                $(targetModal).modal('show');
                $('body').trigger(EVENTS.editModalReady);

                const $sliders = this.editProductModal.find('.js-slider');
                if ($sliders.length > 0) {
                    $sliders.each((index, slider) => {
                        $(slider).not('.slick-initialized').slick();
                    });
                }
            },
            error: (err) => {
                alertMessage(err.responseJSON.errorMessage, 'cc-alert--danger');
            }
        });
    }

    /**
     * Parses the html for a modal window
     * @param {string} html - representing the body and footer of the modal window
     *
     * @return {Object} - Object with properties body and footer.
     */
    parseHtml(html) {
        var $html = $('<div>').append($.parseHTML(html));

        var body = $html.find('.product-quickview');
        var footer = $html.find('.modal-footer').children();

        return { body: body, footer: footer };
    }

    /**
     * Updates details of a product line item
     * @param {Object} data - AJAX response from the server
     * @param {string} uuid - The uuid of the product line item to update
     */
    updateProductDetails(data, uuid) {
        $('.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
    }

    /**
     * Updates the availability of a product line item
     * @param {Object} data - AJAX response from the server
     * @param {string} uuid - The uuid of the product line item to update
     */
    updateAvailability(data, uuid) {
        var lineItem;
        var messages = '';

        for (var i = 0; i < data.items.length; i++) {
            if (data.items[i].UUID === uuid) {
                lineItem = data.items[i];
                break;
            }
        }

        if (lineItem != null) {
            $('.availability-' + lineItem.UUID).empty();

            if (lineItem.availability) {
                if (lineItem.availability.messages) {
                    lineItem.availability.messages.forEach(function (message) {
                        messages += '<p class="line-item-attributes">' + message + '</p>';
                    });
                }

                if (lineItem.availability.inStockDate) {
                    messages += '<p class="line-item-attributes line-item-instock-date">'
                        + lineItem.availability.inStockDate
                        + '</p>';
                }
            }

            $('.availability-' + lineItem.UUID).html(messages);
        }
    }

    /**
     * re-renders the approaching discount messages
     * @param {Object} approachingDiscounts - updated approaching discounts for the cart
     */
    updateApproachingDiscounts(approachingDiscounts) {
        var html = '';
        $(SELECTORS.approachingDiscounts).empty();
        if (approachingDiscounts.length > 0) {
            approachingDiscounts.forEach(function (item) {
                html += '<div class="single-approaching-discount text-center">'
                    + item.discountMsg + '</div>';
            });
        }
        $(SELECTORS.approachingDiscounts).append(html);
    }

    /**
     * Re-renders the order totals and the number of items in the cart
     * @param {Object} data - AJAX response from the server
     */
    updateCartTotals(data) {
        if (data.basketType && data.basketType !== "mixed") {
            $(SELECTORS.shippingCost).empty().append(data.totals.totalShippingCost);
            $(SELECTORS.checkoutBtn).removeClass(CLASSES.hide);
            $(SELECTORS.checkoutBtnModal).addClass(CLASSES.hide);
            $(SELECTORS.showBtnPPExpress).removeClass(CLASSES.hide);
            $(SELECTORS.showBtnPPExpressMiniCart).removeClass(CLASSES.hide);
        }else {
            $(SELECTORS.checkoutBtn).addClass(CLASSES.hide);
            $(SELECTORS.checkoutBtnModal).removeClass(CLASSES.hide);
            $(SELECTORS.showBtnPPExpress).addClass(CLASSES.hide);
            $(SELECTORS.showBtnPPExpressMiniCart).addClass(CLASSES.hide);
        }

        $(SELECTORS.taxTotal).empty().append(data.totals.totalTax);
        $(SELECTORS.grandTotal).empty().append(data.totals.grandTotal);
        $(SELECTORS.subTotal).empty().append(data.totals.subTotal);
        //for coupon 3050 price discount to show in order summary
        $(SELECTORS.coupon3050Total).empty().append(data.totals.coupon3050Total);
        $(SELECTORS.couponSFCCTotal).empty().append(data.totals.couponSFCCTotal);
        this.minicartQty.empty().append(data.numItems);
        this.minicartQty.css('display', () => data.numItems === 0 ? 'none' : 'inline-flex');
        this.minicartLink.attr({
            'aria-label': data.resources.minicartCountOfItems,
            title: data.resources.minicartCountOfItems
        });
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $(SELECTORS.orderDiscountLabel).removeClass(CLASSES.hide);
            $(SELECTORS.orderDiscountTotal).empty()
                .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
        } else {
            $(SELECTORS.orderDiscountLabel).addClass(CLASSES.hide);
        }

        if (data.totals.shippingLevelDiscountTotal.value > 0 && !data.totals.freeShippingTotal && data.basketType && data.basketType != "mixed") {
            $(SELECTORS.shippingDiscountLabel).removeClass(CLASSES.hide);
            $(SELECTORS.shippingDiscountTotal).empty().append('- '
                + data.totals.shippingLevelDiscountTotal.formatted);
        } else {
            $(SELECTORS.shippingDiscountLabel).addClass(CLASSES.hide);
        }
        

        if (data.totals.coupon3050Total && data.totals.coupon3050Total.value > 0) {
            $(SELECTORS.coupon3050Label).removeClass(CLASSES.hide);
            $(SELECTORS.coupon3050Total).empty().append(data.totals.coupon3050Total.formatted);
        } else {
            $(SELECTORS.coupon3050Label).addClass(CLASSES.hide);
        }

        if (data.totals.couponSFCCTotal && data.totals.couponSFCCTotal.value > 0) {
            $(SELECTORS.couponSFCCLabel).removeClass(CLASSES.hide);
            $(SELECTORS.couponSFCCTotal).empty().append(data.totals.couponSFCCTotal.formatted);
        } else {
            $(SELECTORS.couponSFCCLabel).addClass(CLASSES.hide);
        }

        data.items.forEach(function (item) {
            if (data.totals.orderLevelDiscountTotal.value > 0) {
                $(SELECTORS.couponsAndPromo).empty().append(data.totals.discountsHtml);
            }
            if (item.renderedPromotions) {
                $('.item-' + item.UUID).empty().append(item.renderedPromotions);
            } else {
                $('.item-' + item.UUID).empty();
            }
            $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        });

        if (data.approachingDiscounts.length > 0) {
            const approachingDiscount = data.approachingDiscounts[0];
            if (approachingDiscount.percentageThreshold >= 100) {
                $('.js-shipping-discount-component').addClass('d-none');
            } else {
                $('.js-shipping-discount-component').removeClass('d-none');
                $('.js-shipping-discount-text').html(approachingDiscount.discountMsg);
                $('.js-shipping-discount-progress').css('width', approachingDiscount.percentageThreshold + '%');    
            }
        } else {
            $('.js-shipping-discount-component').addClass('d-none');
        }

        if ($('.js-pro-saving').length > 0 && data.proSavingsBeforeSubscription) $('.js-pro-saving').text(data.proSavingsBeforeSubscription);
    }

    /**
     * appends params to a url
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
     */
    appendToUrl(url, params) {
        var newUrl = url;
        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        return newUrl;
    }

    /**
     * Checks whether the basket is valid. if invalid displays error message and disables
     * checkout button
     * @param {Object} data - AJAX response from the server
     */
    validateBasket(data) {
        if (data.valid.error) {
            if (data.valid.message) {
                var errorHtml = '<div class="cc-alert cc-alert--danger valid-cart-error '
                    + 'fade show" role="alert">'
                    + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                    + '<span aria-hidden="true">&times;</span>'
                    + '</button>' + data.valid.message + '</div>';

                $(SELECTORS.cartError).append(errorHtml);
            } else {
                $(SELECTORS.minicartExclusion).empty().append('<div class="row"> '
                    + '<div class="col-12 text-center"> '
                    + '<span>' + data.resources.emptyCartMsg + '</span> '
                    + '</div> '
                    + '</div>');
                this.minicart.addClass(CLASSES.minicartEmpty);
                this.minicartQty.empty().append(data.numItems);
                this.minicartQty.css('display', () => data.numItems === 0 ? 'none' : 'inline-flex');
                this.minicartLink.attr({
                    'aria-label': data.resources.minicartCountOfItems,
                    title: data.resources.minicartCountOfItems
                });
            }
            $(SELECTORS.checkoutBtn).addClass(CLASSES.disabled);
        }
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new Cart($(element));
    });

    base.selectAttribute();
    base.selectAttributeOptions();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
};