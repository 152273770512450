'use strict';

const SELECTORS = {
    element: '.js-change-qty',
    target: '.js-change-qty-input',
    trigger: '.js-change-qty-btn',
};

/**
 * QuantitySelector class that handles the quantity selector component 
 */
class QuantitySelector {
    /**
     * Creates a new QuantitySelector instance.
     * @param {jQuery} element - The jQuery element representing the quantity selector.
     */
    constructor(element) {
        this.element = element;
        this.trigger = element.find(SELECTORS.trigger);
        this.target = element.find(SELECTORS.target);

        this.initEvents();
    }

    /**
     * Initializes the events for the quantity selector.
     */
    initEvents() {
        this.trigger.on('click', (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            this.editQuantity(evt);
        });
    }

    /**
     * Edits the quantity based on the button clicked.
     * @param {Event} evt - The click event.
     */
    editQuantity(evt) {
        const button = $(evt.currentTarget);
        const increase = button.data('increase');
        const target = this.target;
        let quantity = parseInt(target.val(), 10);

        if (isNaN(quantity)) {
            quantity = 1;
        }

        if (!increase && quantity - 1 <= 0) {
            $('body').trigger('quantiy:negative', {target: target});
            return;
        }

        if (increase) {
            quantity++;
        } else {
            quantity = quantity > 1 ? quantity - 1 : 1;
        }

        target.val(quantity);
        target.trigger('input');

        // GA4 EVENT
        $('#update-cart').trigger('click', { increase: increase });
    }
}

module.exports = () => {
    $(SELECTORS.element).each((index, element) => {
        new QuantitySelector($(element));
    });

    $('body').on('product:afterEditItem', (e, uuid) => {
        const $element = $('.product-info.uuid-' + uuid).find(SELECTORS.element);
        if ($element.length) {
            new QuantitySelector($element);
        }
    });
};
